import styled from "styled-components"
import Link from "shared/components/Link"
import { sm } from "mill/utils/breakpoints"
import { paragraphColor, libraryGray } from "mill/utils/colors"

export const Container = styled.div`
  margin: 2rem 0;

  @media ${sm} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`

export const Card = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;
  padding: 1rem;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  color: ${paragraphColor};
  padding: 2rem;
  transition: 0.1s background ease-out;
  ${props => props.right === "true" && `text-align: right;`}
  margin-bottom: 1rem;

  h5 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
    color: #adadad;
  }

  span {
    font-size: 0.9em;
    font-weight: 600;
  }

  i {
    color: ${props => props.theme.colors.primary};
    font-size: 2rem;
  }

  &:hover {
    transition: 0.1s background ease-in;
    background-color: ${libraryGray};
  }

  @media ${sm} {
    min-width: 48%;
    max-width: 48%;
  }
`
