import React from "react"
import Loader from "shared/components/Loader"
import { useQuery } from "@apollo/client"
import PageWrapper from "mill/containers/LearningLibrary/PageWrapper"
import FETCH_LIBRARY_WELCOME_DATA from "mill/graphql/FetchLibraryWelcomeData"
import {
  ArticleWrapper,
  QuestionListings,
  Heading,
  SideNav,
  EditArticleButton
} from "mill/screens/LearningLibrary/sharedStyles.js"
import { LearningLibraryContentDecorator } from "mill/utils/shared/core"
import ArticleStepper from "mill/components/ArticleStepper"
import styled from "styled-components"
import { sm, md } from "mill/utils/breakpoints"
import usePageTitle from "mill/hooks/usePageTitle"
import useTrackPage from "mill/hooks/useTrackPage"

const EmptyState = styled.div`
  text-align: center;
  padding: 0 2rem 2rem 2rem;

  img {
    max-height: 25rem;

    @media ${sm} {
      max-height: 30rem;
    }

    @media ${md} {
      max-height: 35rem;
    }
  }
`

const WelcomePage = () => {
  usePageTitle("Learning library")
  useTrackPage("Learning library")
  const { loading, error, data, networkStatus } = useQuery(
    FETCH_LIBRARY_WELCOME_DATA
  )
  const createMarkup = htmlString => {
    return {
      __html: htmlString
    }
  }

  if (error) return <p>Error :(</p>
  if ((loading && networkStatus !== 2) || !data) return <Loader />
  const welcomeArticle = data.learningLibraryHomepageArticle
  const hasArticles = data.viewer.account.articleCount > 1

  return (
    <ArticleWrapper>
      <QuestionListings>
        <Heading>Welcome to the Learning Library</Heading>
        <LearningLibraryContentDecorator>
          <div dangerouslySetInnerHTML={createMarkup(welcomeArticle.body)} />
        </LearningLibraryContentDecorator>
        {!hasArticles ? (
          <EmptyState>
            <div>
              <h2>There are no library articles yet</h2>
              <p>
                The learning library stores information and training material
                regardless of whether or not you're on a campaign. All it needs
                now are some articles...
              </p>
            </div>
          </EmptyState>
        ) : (
          <ArticleStepper
            nextArticle={welcomeArticle.nextPublishedArticle}
            previousArticle={null}
          />
        )}
      </QuestionListings>
      <SideNav>
        {data.viewer.accountAdmin && (
          <EditArticleButton
            to={`/admin/learning-library/articles/${welcomeArticle.id}`}>
            <i className="fa fa-pencil" />
            <span>Edit article</span>
          </EditArticleButton>
        )}
      </SideNav>
    </ArticleWrapper>
  )
}

export default PageWrapper(WelcomePage)
