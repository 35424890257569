import React from "react"
import { Container, Card } from "./styles"

const ArticleStepper = props => {
  return (
    <Container>
      {props.nextArticle && (
        <Card to={`/learning-library/articles/${props.nextArticle.id}`}>
          <div>
            <h5>Next</h5>
            <span>{props.nextArticle.title}</span>
          </div>
          <i className="fa fa-long-arrow-right" />
        </Card>
      )}
      {props.previousArticle && (
        <Card
          to={`/learning-library/articles/${props.previousArticle.id}`}
          right="true">
          <i className="fa fa-long-arrow-left" />
          <div>
            <h5>Previous</h5>
            <span>{props.previousArticle.title}</span>
          </div>
        </Card>
      )}
    </Container>
  )
}

export default ArticleStepper
